import React from "react";
import { Dark } from "../../../../Theme/Theme";
import moment from "moment";
import { NumericFormat } from "react-number-format";
export default function ClientCard(props) {
  const i = props.item;

  let brokerage = i.brokerage == null ? 0 : parseFloat(i.brokerage).toFixed(0);
  let brokerage_broker =
    i.brokerage_broker == null ? 0 : parseFloat(i.brokerage_broker).toFixed(0);
  let profit_loss =
    i.profit_loss == null ? 0 : parseFloat(i.profit_loss).toFixed(0);

  let gross = parseFloat(i.gross).toFixed(0);

  let sharing = parseFloat(i.sharing).toFixed(0);

  let netamount = parseFloat(i.netamount).toFixed(0);

  return (
    <div
      style={{
        height: 50,
        display: "flex",
        backgroundColor: Dark.background,
        justifyContent: "center",
        alignItems: "center",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
        textTransform: "uppercase",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
          textAlign:"center"
        }}
      >
        {i.master_username}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
          textAlign:"center"
        }}
      >
        {i.broker_name}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
          textAlign:"center"
        }}
      >
        {i.client_username}({i.client_name})
      </div>
      <div
        style={{
          color: profit_loss > 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        <NumericFormat
          value={profit_loss}
          displayType={"text"}
          thousandSeparator={true}
        />
        ₹
      </div>
      <div
        style={{
          color: Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        <NumericFormat
          value={brokerage}
          displayType={"text"}
          thousandSeparator={true}
        />
        ₹
      </div>
      <div
        style={{
          color: Dark.buy,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        <NumericFormat
          value={brokerage_broker}
          displayType={"text"}
          thousandSeparator={true}
        />
        ₹
      </div>

      <div
        style={{
          color: gross > 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        <NumericFormat
          value={gross}
          displayType={"text"}
          thousandSeparator={true}
        />
        ₹
      </div>

      <div
        style={{
          color: sharing > 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        <NumericFormat
          value={sharing}
          displayType={"text"}
          thousandSeparator={true}
        />
        ₹ ({i.broker_sharing}%)
      </div>

      <div
        style={{
          color: netamount > 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        <NumericFormat
          value={netamount}
          displayType={"text"}
          thousandSeparator={true}
        />
        ₹
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        <div
          onClick={() => {
            props.name(i.client_username);
            props.trade(i.trade);
            props.show();
          }}
          style={{
            padding: 10,
            backgroundColor: Dark.primary,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 10,
          }}
        >
          TRADE
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 10,
        }}
      >
        <div style={{ paddingRight: 5 }}>
          {moment(i.start).format("DD-MM-YYYY")}{" "}
        </div>
        <div>TO</div>
        <div style={{ paddingLeft: 5 }}>
          {" "}
          {moment(i.end).format("DD-MM-YYYY")}
        </div>
      </div>
    </div>
  );
}
