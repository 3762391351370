import React, { useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import Loading from "react-loading";
const backend = new Backend();
export default function SymbolCard(props) {
  const item = props.item;

  const [loading, setLoading] = useState(false);

  const symbol_mcx_delete = () => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to delete mcx symbol.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setLoading(true);
            let data = {
              token: localStorage.getItem("token"),
              id: localStorage.getItem("id"),
              username: localStorage.getItem("username"),
              server: localStorage.getItem("server"),
              symbol_id: item.id,
            };

            backend.symbol_mcx_delete(data).then((r) => {
              setLoading(false);
              if (r.error == "False") {
                toast.success(r.message, {
                  backgroundColor: Dark.buy,
                  color: Dark.text,
                });
                props.load();
              } else {
                toast.error(r.message, {
                  backgroundColor: Dark.sell,
                  color: Dark.text,
                });
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };

  const symbol_mcx_sq_active = () => {
    confirmAlert({
      title: "Confirm to Sq Mode",
      message: "Are you sure to symbol sq mode.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let data = {
              token: localStorage.getItem("token"),
              id: localStorage.getItem("id"),
              username: localStorage.getItem("username"),
              server: localStorage.getItem("server"),
              symbol_id: item.id,
            };

            backend.symbol_mcx_sq_active(data).then((r) => {
              setLoading(false);
              if (r.error == "False") {
                toast.success(r.message, {
                  backgroundColor: Dark.buy,
                  color: Dark.text,
                });
                props.load();
              } else {
                toast.error(r.message, {
                  backgroundColor: Dark.sell,
                  color: Dark.text,
                });
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };

  const symbol_mcx_sq_deactive = () => {
    confirmAlert({
      title: "Confirm to Sq Mode",
      message: "Are you sure to symbol decative sq mode.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let data = {
              token: localStorage.getItem("token"),
              id: localStorage.getItem("id"),
              username: localStorage.getItem("username"),
              server: localStorage.getItem("server"),
              symbol_id: item.id,
            };

            backend.symbol_mcx_sq_deactive(data).then((r) => {
              setLoading(false);
              if (r.error == "False") {
                toast.success(r.message, {
                  backgroundColor: Dark.buy,
                  color: Dark.text,
                });
                props.load();
              } else {
                toast.error(r.message, {
                  backgroundColor: Dark.sell,
                  color: Dark.text,
                });
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };

  return (
    <div
      style={{
        color: Dark.text,
        height: 55,
        margin: 10,
        backgroundColor: Dark.secondary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10,
        paddingLeft: 10,
      }}
    >
      <div style={{ flex: 1 }}>
        <div>{item.symbol_display}</div>
        <div style={{ fontSize: 12, paddingTop: 5, color: Dark.sell }}>
          {moment(item.expiry_date).format("DD-MMM")} - (
          {moment(item.expiry_date).format("YYYY-MM-DD HH:mm:ss")})
        </div>
      </div>
      <div style={{ flex: 1, textAlign: "center" }}>
        {parseFloat(item.min_qty).toFixed(2)}
      </div>
      <div style={{ flex: 1, textAlign: "center" }}>
        {parseFloat(item.qty_per_order).toFixed(2)}
      </div>
      <div style={{ flex: 1, textAlign: "center" }}>
        {parseFloat(item.total_quantity).toFixed(2)}
      </div>
      <div style={{ flex: 1, textAlign: "center" }}>
        {parseFloat(item.lot_size).toFixed(2)}
      </div>
      <div
        style={{
          flex: 1,
          textAlign: "center",
          color: item.only_sq == 1 ? Dark.buy : Dark.sell,
        }}
      >
        {item.only_sq == 1 ? "Active" : "Deactive"}
      </div>
      <div style={{ flex: 1, textAlign: "center" }}>
        {moment(item.date_created).format("DD-MM-YYYY HH:mm:ss")}
      </div>
    </div>
  );
}
