import React from "react";
import { Dark } from "../../../../../../Theme/Theme";
import moment from "moment";
import { View, Text } from "@react-pdf/renderer";

export default function PendingCard(props) {
  const item = props.item;
  let m2m = item.profit_loss + item.brokerage_client_amt;
  return (
    <View
      style={{
        height: 35,
        backgroundColor: Dark.background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
      }}
    >
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Text style={{ fontSize: 9, color: Dark.text }}>
          {item.client_username}{" "}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 9,
            color: Dark.text,
          }}
        >
          {item.symbol}
        </Text>
        <Text
          style={{
            fontSize: 8,
            color: Dark.text,
            marginTop: 3,
          }}
        >
          {moment(item.expiry_date).format("DD-MMM")}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 9,
            color: item.buy_sell == 0 ? Dark.buy : Dark.sell,
          }}
        >
          {item.buy_sell == 0 ? "BUY" : "SELL"}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 9,
            color: Dark.text,
            textAlign: "center",
          }}
        >
          {item.qty}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 9,
            color: Dark.text,
            textAlign: "center",
          }}
        >
          {item.price}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 9,
            color: Dark.sell,
            textAlign: "center",
          }}
        >
          {item.high_mid == 0 ? "HIGH" : "MID"}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 9,
            color: item.limit_sl == 0 ? Dark.primary : Dark.sell,
            textAlign: "center",
          }}
        >
          {item.limit_sl == 0 ? "LIMIT" : "STOP-LOSS"}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 9,
            color:
              item.status == 0
                ? Dark.text
                : item.status == 1
                ? Dark.buy
                : Dark.sell,
            textAlign: "center",
          }}
        >
          {item.status == 0 ? "WAITING" : item.msg}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 9,
            color: Dark.text,
            textAlign: "center",
          }}
        >
          {moment(item.date_created).format("DD-MM-YYYY HH:mmss")}
        </Text>
      </View>
    </View>
  );
}
