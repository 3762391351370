import React from "react";
import { Dark } from "../../../../Theme/Theme";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

export default function Menu(props) {
  return (
    <div
      style={{
        height: 40,
        display: "flex",
        backgroundColor: Dark.primary,
        justifyContent: "center",
        alignItems: "center",
        textTransform: "uppercase",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Master</div>
        <div onClick={() => props.sort("MasterUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("MasterDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Client</div>
        <div onClick={() => props.sort("ClientUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("ClientDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1.5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Synbol</div>
        <div onClick={() => props.sort("SymbolUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("SymbolDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Buy-Sell</div>
        <div onClick={() => props.sort("BuySellUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("BuySellDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Net-Qty</div>
        <div onClick={() => props.sort("QtyUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div onClick={() => props.sort("QtyDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Rate</div>
        <div onClick={() => props.sort("RateUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div onClick={() => props.sort("RateDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Live Rate
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        M2M
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Brokerage
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        P/L
      </div>
    </div>
  );
}
