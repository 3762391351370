import React from "react";
import { Dark } from "../../../../Theme/Theme";

export default function Menu(props) {
  return (
    <div
      style={{
        color: Dark.text,
        height: 40,
        margin: 10,
        backgroundColor: Dark.primary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10,
        paddingLeft: 20,
      }}
    >
      <div style={{ flex: 1 }}>NAME</div>
      <div style={{ flex: 1, textAlign: "center" }}>MIN ORDER</div>
      <div style={{ flex: 1, textAlign: "center" }}>PER ORDER</div>
      <div style={{ flex: 1, textAlign: "center" }}>TOTAL ORDER</div>
      <div style={{ flex: 1, textAlign: "center" }}>LOT SIZE</div>
      <div style={{ flex: 1, textAlign: "center" }}>ONLY SQ</div>
      <div style={{ flex: 1, textAlign: "center" }}>DATE</div>
    </div>
  );
}
