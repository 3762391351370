import React, { useEffect, useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import { InputText } from "primereact/inputtext";
import "./Edit.css";
import Loading from "react-loading";
import Active from "../Active/Active";
import Future from "../Active/Future";
import Mcx from "../Active/Mcx";
import Options from "../Active/Options";
import OnlySq from "../Active/OnlySq";
import Intraday from "../Active/Intraday";
import TPassword from "../../../../../Healer/TPassword/TPassword";
import { Dialog } from "primereact/dialog";

const backend = new Backend();

export default function Edit(props) {
  const master = props.master;

  const [loading, setLoading] = useState(false);

  const [show_edit_master_details, setshow_edit_master_details] =
    useState(false);
  const [show_edit_master_brokerage, setshow_edit_master_brokerage] =
    useState(false);

  const [masterS, setMasterS] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [sharing, setSharing] = useState("");
  const [password, setPassword] = useState("");
  const [tpassword, setTPassword] = useState("");

  const [future_min_volume, setfuture_min_volume] = useState("");
  const [mcx_min_volume, setmcx_min_volume] = useState("");
  const [mcx_min_lot, setmcx_min_lot] = useState("");
  const [options_min_lot, setoptions_min_lot] = useState("");

  useEffect(() => {
    loadMasterSingle();
  }, []);

  const loadMasterSingle = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      master_id: master.id,
    };

    backend.single_master(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setMasterS(r.master);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const edit_master_details = () => {
    setLoading(true);
    setshow_edit_master_details(false);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      master_id: masterS.id,
      name: name == "" ? masterS.name : name,
      mobile: mobile == "" ? masterS.mobile : mobile,
      sharing: sharing == "" ? masterS.sharing : sharing,
      password: password,
      tpassword: tpassword,
    };

    backend.edit_master_details(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        loadMasterSingle();
        props.reload();
        setName("");
        setSharing("");
        setMobile("")
        setPassword("");
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const edit_master_brokerage = () => {
    setshow_edit_master_brokerage(false);
    setLoading(true);

    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      master_id: masterS.id,
      future_min_volume:
        future_min_volume == "" ? masterS.future_min_volume : future_min_volume,
      mcx_min_volume:
        mcx_min_volume == "" ? masterS.mcx_min_volume : mcx_min_volume,
      mcx_min_lot: mcx_min_lot == "" ? masterS.mcx_min_lot : mcx_min_lot,
      options_min_lot:
        options_min_lot == "" ? masterS.options_min_lot : options_min_lot,
      tpassword: tpassword,
    };

    backend.edit_master_brokerage(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        setfuture_min_volume("");
        setmcx_min_volume("");
        setmcx_min_lot("");
        setoptions_min_lot("");
        loadMasterSingle();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: "90%",
      }}
    >
      <div
        key={masterS}
        style={{
          flex: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            color: Dark.text,
            padding: 10,
            textTransform: "uppercase",
            backgroundColor: Dark.primary,
            display: "flex",
          }}
        >
          <div style={{ flex: 1 }}>Master Details</div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text, marginTop: 5 }}>{masterS.name}</div>
            <div className="loginInputEditAdmin">
              <InputText
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="Name"
              />
            </div>
          </div>

          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text, marginTop: 5 }}>{masterS.mobile}</div>
            <div className="loginInputEditAdmin">
              <InputText
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
                placeholder="Mobile"
                type="number"
              />
            </div>
          </div>

          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text, marginTop: 5 }}>
              {masterS.sharing}
            </div>
            <div className="loginInputEditAdmin">
              <InputText
                value={sharing}
                type="number"
                onChange={(e) => {
                  setSharing(e.target.value);
                }}
                placeholder="Sharing"
              />
            </div>
          </div>

          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text, marginTop: 5 }}>PASSWORD</div>
            <div className="loginInputEditAdmin">
              <InputText
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder="Password"
              />
            </div>
          </div>
          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              onClick={() =>
                loading ? null : setshow_edit_master_details(true)
              }
              style={{
                color: Dark.text,
                height: 40,
                width: 100,
                backgroundColor: Dark.primary,
                paddingLeft: 25,
                paddingRight: 25,
                borderRadius: 7,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loading ? (
                <Loading type="spin" height={25} width={25} color={Dark.text} />
              ) : (
                "SAVE"
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            color: Dark.text,
            padding: 10,
            textTransform: "uppercase",
            backgroundColor: Dark.primary,
            display: "flex",
          }}
        >
          <div style={{ flex: 1 }}>Master brokerage</div>
          <div>
            Brokerage
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text }}>FUTURE</div>
            <div className="loginInputEditAdmin">
              <div style={{ textAlign: "center", marginTop: 5 }}>
                {masterS.future_min_volume}
              </div>
              <InputText
                value={future_min_volume}
                onChange={(e) => {
                  setfuture_min_volume(e.target.value);
                }}
                type="number"
                placeholder="Future Brok"
              />
            </div>
          </div>
          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ color: Dark.text, marginTop: 5 }}>MCX</div>
            <div className="loginInputEditAdmin">
              <div style={{ textAlign: "center", marginTop: 5 }}>
                Lot {masterS.mcx_min_lot}
              </div>
              <InputText
                value={mcx_min_lot}
                onChange={(e) => {
                  setmcx_min_lot(e.target.value);
                }}
                placeholder="Mcx Brok"
              />
            </div>
          </div>

          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ color: Dark.text, marginTop: 5 }}>MCX</div>
            <div className="loginInputEditAdmin">
              <div style={{ textAlign: "center", marginTop: 5 }}>
                Volume {masterS.mcx_min_volume}
              </div>
              <InputText
                value={mcx_min_volume}
                onChange={(e) => {
                  setmcx_min_volume(e.target.value);
                }}
                placeholder="Mcx Brok"
              />
            </div>
          </div>
          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text, marginTop: 5 }}>OPTIONS</div>
            <div className="loginInputEditAdmin">
              <div style={{ textAlign: "center", marginTop: 5 }}>
                {masterS.options_min_lot}
              </div>
              <InputText
                value={options_min_lot}
                onChange={(e) => {
                  setoptions_min_lot(e.target.value);
                }}
                placeholder="Options Brok"
              />
            </div>
          </div>
          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              onClick={() =>
                loading ? null : setshow_edit_master_brokerage(true)
              }
              style={{
                color: Dark.text,
                height: 40,
                width: 100,
                backgroundColor: Dark.primary,
                paddingLeft: 25,
                paddingRight: 25,
                borderRadius: 7,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loading ? (
                <Loading type="spin" height={25} width={25} color={Dark.text} />
              ) : (
                "SAVE"
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 0.5,
          height: "55px",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Active
          status={masterS.status}
          master_id={masterS.id}
          reload={loadMasterSingle}
        />
        <Future
          status={masterS.future}
          master_id={masterS.id}
          reload={loadMasterSingle}
        />
        <Mcx
          status={masterS.mcx}
          master_id={masterS.id}
          reload={loadMasterSingle}
        />
        <Options
          status={masterS.options}
          master_id={masterS.id}
          reload={loadMasterSingle}
        />

        <OnlySq
          status={masterS.only_sq}
          master_id={masterS.id}
          reload={loadMasterSingle}
        />
        <Intraday
          status={masterS.intraday}
          master_id={masterS.id}
          reload={loadMasterSingle}
        />
      </div>

      <Dialog
        visible={show_edit_master_details}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setshow_edit_master_details(false)}
      >
        <TPassword
          Update={edit_master_details}
          tpassword={(a) => setTPassword(a)}
        />
      </Dialog>

      <Dialog
        visible={show_edit_master_brokerage}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setshow_edit_master_brokerage(false)}
      >
        <TPassword
          Update={edit_master_brokerage}
          tpassword={(a) => setTPassword(a)}
        />
      </Dialog>
    </div>
  );
}
