import React from "react";
import { Dark } from "../../../../../Theme/Theme";
import moment from "moment";

export default function PosistionCard(props) {
  const item = props.item;
  return (
    <div
      style={{
        height: 50,
        display: "flex",
        backgroundColor: Dark.background,
        justifyContent: "center",
        alignItems: "center",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
        textTransform: "uppercase",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {item.master_username}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {item.client_username}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1.5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
          flexDirection: "column",
          fontSize: 16,
        }}
      >
        <div>{item.symbol}</div>
        <div style={{ fontSize: 10, paddingTop: 5 }}>
          {moment(item.expiry_date).format("DD-MMM")}
        </div>
      </div>
      <div
        style={{
          color: item.buy_sell == 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {item.buy_sell == 0 ? "BUY" : "SELL"}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {item.qty}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {item.rate}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {item.rate}
      </div>
      <div
        style={{
          color: item.rate > 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {item.rate}
      </div>
      <div
        style={{
          color: Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {item.rate}
      </div>
      <div
        style={{
          color: item.rate > 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {item.rate}
      </div>
    </div>
  );
}
