import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import SocketContect from "./Component/Healer/SocketContect";

import NiftyBankNifty from "./Component/Screen/MainStack/NiftyBankNifty/NiftyBankNifty";

import TopView from "./Component/Screen/MainStack/TopView/TopView";

import { Dark } from "./Component/Theme/Theme";
import { ToastContainer } from "react-toast";
import Login from "./Component/Screen/LoginStack/Login/Login";

import Home from "./Component/Screen/MainStack/Home/Home";
import Watchlist from "./Component/Screen/MainStack/Watchlist/Watchlist";
import Master from "./Component/Screen/MainStack/Master/Master";
import Client from "./Component/Screen/MainStack/Client/Client";

import Position from "./Component/Screen/MainStack/Position/Position";
import PositionMaster from "./Component/Screen/MainStack/PositionMaster/PositionMaster";
import PositionClient from "./Component/Screen/MainStack/PositionClient/PositionClient";
import PositionTrade from "./Component/Screen/MainStack/PositionTrade/PositionTrade";

import Trade from "./Component/Screen/MainStack/Trade/Trade";
import Pending from "./Component/Screen/MainStack/Pending/Pending";
import Holding from "./Component/Screen/MainStack/Holding/Holding";

import ClientReport from "./Component/Screen/MainStack/Report/Client/Client";
import MasterReport from "./Component/Screen/MainStack/Report/Master/Master";

import Brokerage from "./Component/Screen/MainStack/Brokerage/Brokerage";

import DeleteTrade from "./Component/Screen/MainStack/Delete/Trade/Trade";
import DeletePosition from "./Component/Screen/MainStack/Delete/Position/Position";
import DeletePending from "./Component/Screen/MainStack/Delete/Pending/Pending";

import FutureSymbol from "./Component/Screen/MainStack/Symbol/Future/Future";
import McxSymbol from "./Component/Screen/MainStack/Symbol/Mcx/Mcx";
import OptionsSymbol from "./Component/Screen/MainStack/Symbol/Options/Options";

import useWindowSize from "./Component/Healer/WindowSize";

import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "./App.css";

import "react-confirm-alert/src/react-confirm-alert.css";

import io from "socket.io-client";

var socket = io("https://arrowtrad.com:50000");

export default function App() {
  const height = useWindowSize();

  return (
    <div style={{ backgroundColor: Dark.secondary, height: height }}>
      <ToastContainer className="toplevel" position="top-right" delay={3000} />
      {localStorage.getItem("AuthState") == "1" ? (
        <BrowserRouter>
          <SocketContect.Provider value={socket}>
            <NiftyBankNifty />
            <TopView />
            <div>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="*" element={<Home />} />
                <Route path="/Home" element={<Home />} />
                <Route path="/Watchlist" element={<Watchlist />} />
                <Route path="/Master" element={<Master />} />
                <Route path="/Client" element={<Client />} />
                <Route path="/Position" element={<Position />} />
                <Route path="/PositionClient" element={<PositionClient />} />
                <Route path="/PositionMaster" element={<PositionMaster />} />
                <Route path="/PositionTrade" element={<PositionTrade />} />
                <Route path="/Trade" element={<Trade />} />
                <Route path="/Pending" element={<Pending />} />
                <Route path="/Holding" element={<Holding />} />
                <Route path="/ClientReport" element={<ClientReport />} />
                <Route path="/MasterReport" element={<MasterReport />} />
                <Route path="/Brokerage" element={<Brokerage />} />

                <Route path="/DeleteTrade" element={<DeleteTrade />} />
                <Route path="/DeletePosition" element={<DeletePosition />} />
                <Route path="/DeletePending" element={<DeletePending />} />

                <Route path="/FutureSymbol" element={<FutureSymbol />} />
                <Route path="/McxSymbol" element={<McxSymbol />} />
                <Route path="/OptionsSymbol" element={<OptionsSymbol />} />
              </Routes>
            </div>
          </SocketContect.Provider>
        </BrowserRouter>
      ) : (
        <div>
          <Login />
        </div>
      )}
    </div>
  );
}
