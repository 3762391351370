import React, { useEffect, useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import { InputText } from "primereact/inputtext";
import { IoSearch } from "react-icons/io5";

export default function Total(props) {
  const standing = props.standing;

  const [total, setTotal] = useState(0);
  const [buy, setBuy] = useState(0);
  const [sell, setSell] = useState(0);

  const [fut, setFut] = useState(0);
  const [mcx, setMcx] = useState(0);
  const [fo, setFo] = useState(0);

  const [search, setSearch] = useState("");

  useEffect(() => {
    Filter();
  }, [standing]);

  const Filter = () => {
    if (standing.length > 0) {
      var buy = standing.filter((x) => x.buy_sell == 0);
      var sell = standing.filter((x) => x.buy_sell == 1);
      var fut = standing.filter((x) => x.type == "fut");
      var mcx = standing.filter((x) => x.type == "mcx");
      var fo = standing.filter((x) => x.type == "fo");

      setBuy(buy);
      setSell(sell);
      setTotal(standing);
      setFut(fut);
      setMcx(mcx);
      setFo(fo);
    }
  };

  const searchSymbol = (a) => {
    if (a.length > 1) {
      var data = standing.filter((obj) =>
        Object.keys(obj).some((key) =>
          obj[key] == null
            ? null
            : obj[key]
                .toString()
                .toLowerCase()
                .includes(a.toString().toLowerCase())
        )
      );
      props.Update(data);
    } else {
      props.Update(standing);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        height: 45,
        backgroundColor: Dark.primary,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => {
            props.Update(total);
          }}
          style={{
            padding: 10,
            backgroundColor: Dark.background,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 10,
            color: Dark.text,
          }}
        >
          TOTAL : {total.length}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => {
            props.Update(buy);
          }}
          style={{
            padding: 10,
            backgroundColor: Dark.background,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 10,
            color: Dark.buy,
          }}
        >
          BUY : {buy.length}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => {
            props.Update(sell);
          }}
          style={{
            padding: 10,
            backgroundColor: Dark.background,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 10,
            color: Dark.sell,
          }}
        >
          SELL : {sell.length}
        </div>
      </div>

      {fut.length > 0 ? (
        <div
          style={{
            flex: 1,
            height: "45px",
            color: Dark.text,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => {
              props.Update(fut);
            }}
            style={{
              padding: 10,
              backgroundColor: Dark.background,
              paddingLeft: 20,
              paddingRight: 20,
              borderRadius: 10,
              color: Dark.text,
            }}
          >
            FUTURE : {fut.length}
          </div>
        </div>
      ) : null}

      {mcx.length > 0 ? (
        <div
          style={{
            flex: 1,
            height: "45px",
            color: Dark.text,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => {
              props.Update(mcx);
            }}
            style={{
              padding: 10,
              backgroundColor: Dark.background,
              paddingLeft: 20,
              paddingRight: 20,
              borderRadius: 10,
              color: Dark.text,
            }}
          >
            MCX {mcx.length}
          </div>
        </div>
      ) : null}

      {fo.length > 0 ? (
        <div
          style={{
            flex: 1,
            height: "45px",
            color: Dark.text,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => {
              props.Update(fo);
            }}
            style={{
              padding: 10,
              backgroundColor: Dark.background,
              paddingLeft: 20,
              paddingRight: 20,
              borderRadius: 10,
              color: Dark.text,
            }}
          >
            OPTIONS {fo.length}
          </div>
        </div>
      ) : null}

      <div style={{ marginRight: 20 }}>
        <div
          style={{
            marginRight: 10,
            width: "250px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            paddingRight: 10,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IoSearch style={{ color: Dark.text, fontSize: 22 }} />
          </div>
          <div>
            <InputText
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                searchSymbol(e.target.value);
              }}
              style={{
                height: 40,
                backgroundColor: Dark.background,
                background: Dark.background,
                borderStyle: "none",
                width: "180px",
                color: Dark.text,
              }}
              placeholder="Search "
            />
          </div>
        </div>
      </div>
    </div>
  );
}
