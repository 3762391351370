import React, { useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import Loading from "react-loading";
const backend = new Backend();

export default function Trade(props) {
  const [loading, setLoading] = useState(false);

  const Update = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      master_id: props.master_id,
      trade: props.status == 0 ? 1 : 0,
    };

    backend.trade_delete_permission(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        props.reload();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div>
      <div
        key={props.status}
        onClick={Update}
        style={{
          padding: 10,
          backgroundColor: props.status == 0 ? Dark.sell : Dark.buy,
          paddingLeft: 20,
          paddingRight: 20,
          borderRadius: 7,
          color: Dark.text,
        }}
      >
        {loading ? (
          <Loading type="spin" height={25} width={25} color={Dark.text} />
        ) : (
          "TRADE DELETE"
        )}
      </div>
    </div>
  );
}
