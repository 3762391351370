import React, { useContext, useEffect, useState } from "react";
import { Dark, Light } from "../../../../Theme/Theme";
import OtherCard from "./OtherCard";
import Backend from "../../../../Backend/Backend";
import SocketContext from "../../../../Healer/SocketContect";
import { toast } from "react-toast";
const backend = new Backend();

let watch = 0;
let fatak = 0;
let fsq = 0;
let limit = 0;

export default function ExtraCard() {
  const socket = useContext(SocketContext);

  const [reload, setReload] = useState(false);

  const [loading, setLoading] = useState(0);

  const [others, setOther] = useState([]);

  useEffect(() => {
    loadOther();
    socket.on("super", notification);
    return () => {
      socket.off("super", notification);
    };
  }, [reload]);

  const notification = (a) => {

    if (a.super == localStorage.getItem("id")) {
      loadOther(); 
    }
  };

  const loadOther = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      
      username: localStorage.getItem("username"),
    };

    backend.other(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setOther(r.other);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };



  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          color: Dark.text,
          backgroundColor: Dark.primary,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          display: "flex",
        }}
      >
        <div
          style={{
            flex: 1,
            height: 55,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => {
              setReload(!reload);
              if (watch == 1) {
                watch = 0;
              } else {
                watch = 1;
              }
            }}
            style={{
              padding: 10,
              backgroundColor: watch == 1 ? Dark.buy : Dark.background,
              borderRadius: 10,
              marginRight: 5,
            }}
          >
            WATCH
          </div>
          <div
            onClick={() => {
              setReload(!reload);
              if (limit == 1) {
                limit = 0;
              } else {
                limit = 1;
              }
            }}
            style={{
              padding: 10,
              backgroundColor: limit == 1 ? Dark.buy : Dark.background,
              borderRadius: 10,
              marginRight: 5,
            }}
          >
            LIMIT
          </div>

          <div
            onClick={() => {
              setReload(!reload);
              if (fsq == 1) {
                fsq = 0;
              } else {
                fsq = 1;
              }
            }}
            style={{
              padding: 10,
              backgroundColor: fsq == 1 ? Dark.buy : Dark.background,
              borderRadius: 10,
              marginRight: 5,
            }}
          >
            FSQ
          </div>
          <div
            onClick={() => {
              setReload(!reload);
              if (fatak == 1) {
                fatak = 0;
              } else {
                fatak = 1;
              }
            }}
            style={{
              padding: 10,
              backgroundColor: fatak == 1 ? Dark.buy : Dark.background,
              borderRadius: 10,
              marginRight: 5,
            }}
          >
            FATAK
          </div>
        </div>
      </div>
      <div
        className="scroll"
        style={{
          display: "flex",
          height: "61vh",
          overflowY: "scroll",
          flexDirection: "column",
        }}
      >
        {others.length > 0 ? (
          others.map((i) => {
            return (
              <OtherCard
                item={i}
                watch={watch}
                limit={limit}
                fsq={fsq}
                fatak={fatak}
              />
            );
          })
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              textTransform:"uppercase"
            }}
          >
            <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
              {" "}
              Nothing here
            </div>
            <div style={{ color: Dark.text }}>No Data Found.</div>
          </div>
        )}
      </div>
    </div>
  );
}
