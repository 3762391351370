const Dark = {
  primary: "#2139b7",
  background: "#000000",
  text: "#ffffff",
  secondary: "#14161b",
  buy: "#1da750",
  sell: "#ff3d32",
};
const Light = {
  background: "#FFFFFF",
  primary: "#ef9704",
  text: "#000000",
  secoundry: "#f2f2f2",
  buy: "#1da750",
  sell: "#ff3d32",
};

export { Dark, Light };
