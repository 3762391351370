import React, { useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import { InputText } from "primereact/inputtext";
import "./Update.css";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";

const backend = new Backend();

export default function UpdateMarquee(props) {
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const add_marquee = () => {
    if (msg == "") {
      toast.error("Invalid Message !!", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else {
      setLoading(true);

      let data = {
        token: localStorage.getItem("token"),
        id: localStorage.getItem("id"),
        username: localStorage.getItem("username"),
        
        msg: msg,
      };

      backend.update_marquee(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          toast(r.message, {
            backgroundColor: Dark.buy,
            color: Dark.text,
          });
          props.load();
          props.close();
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: 45,
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        ESC TO CLOSE
      </div>

      <div
        style={{
          height: 80,
          margin: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="loginInputUpdate">
          <InputText
            value={msg}
            onChange={(e) => {
              setMsg(e.target.value);
            }}
            placeholder="Enter Message "
          />
        </div>
      </div>
      <div
        onClick={loading ? null : add_marquee}
        style={{
          height: 45,
          backgroundColor: Dark.primary,
          margin: 10,
          width: "80%",
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        SUBMIT
      </div>
    </div>
  );
}
