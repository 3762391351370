import React, { useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import { InputText } from "primereact/inputtext";
import { IoSearch } from "react-icons/io5";
export default function Total(props) {
  const trade = props.trade;

  const [total, setTotal] = useState(0);
  const [buy, setBuy] = useState(0);
  const [sell, setSell] = useState(0);

  const [fut, setFut] = useState(0);
  const [mcx, setMcx] = useState(0);
  const [fo, setFo] = useState(0);

  const [search, setSearch] = useState("");

  useEffect(() => {
    Filter();
  }, [trade]);

  const Filter = () => {
    if (trade.length > 0) {
      var buy = trade.filter((x) => x.buy_sell == 0);
      var sell = trade.filter((x) => x.buy_sell == 1);
      var fut = trade.filter((x) => x.script_type == "fut");
      var mcx = trade.filter((x) => x.script_type == "mcx");
      var fo = trade.filter((x) => x.script_type == "fo");

      setBuy(buy.length);
      setSell(sell.length);
      setTotal(trade.length);
      setFut(fut.length);
      setMcx(mcx.length);
      setFo(fo.length);
    }
  };

  const searchSymbol = (a) => {
    if (a.length > 1) {
      var data = trade.filter((obj) =>
        Object.keys(obj).some((key) =>
          obj[key] == null
            ? null
            : obj[key]
                .toString()
                .toLowerCase()
                .includes(a.toString().toLowerCase())
        )
      );
      props.Update(data);
    } else {
      props.Update(trade);
    }
  };

  

  return (
    <div
      style={{
        display: "flex",
        height: 45,
        backgroundColor: Dark.primary,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: 10,
            backgroundColor: Dark.background,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 10,
            color: Dark.text,
          }}
        >
          TOTAL : {total}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: 10,
            backgroundColor: Dark.background,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 10,
            color: Dark.buy,
          }}
        >
          BUY : {buy}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: 10,
            backgroundColor: Dark.background,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 10,
            color: Dark.sell,
          }}
        >
          SELL : {sell}
        </div>
      </div>

      {fut > 0 ? (
        <div
          style={{
            flex: 1,
            height: "45px",
            color: Dark.text,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              padding: 10,
              backgroundColor: Dark.background,
              paddingLeft: 20,
              paddingRight: 20,
              borderRadius: 10,
              color: Dark.text,
            }}
          >
            FUTURE : {fut}
          </div>
        </div>
      ) : null}

      {mcx > 0 ? (
        <div
          style={{
            flex: 1,
            height: "45px",
            color: Dark.text,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              padding: 10,
              backgroundColor: Dark.background,
              paddingLeft: 20,
              paddingRight: 20,
              borderRadius: 10,
              color: Dark.text,
            }}
          >
            MCX {mcx}
          </div>
        </div>
      ) : null}

      {fo > 0 ? (
        <div
          style={{
            flex: 1,
            height: "45px",
            color: Dark.text,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              padding: 10,
              backgroundColor: Dark.background,
              paddingLeft: 20,
              paddingRight: 20,
              borderRadius: 10,
              color: Dark.text,
            }}
          >
            OPTIONS {fo}
          </div>
        </div>
      ) : null}

      <div style={{ marginRight: 20 }}>
        <div
          style={{
            marginRight: 10,
            width: "250px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            paddingRight: 10,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IoSearch style={{ color: Dark.text, fontSize: 22 }} />
          </div>
          <div>
            <InputText
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                searchSymbol(e.target.value);
              }}
              style={{
                height: 40,
                backgroundColor: Dark.background,
                background: Dark.background,
                borderStyle: "none",
                width: "180px",
                color: Dark.text,
              }}
              placeholder="Search "
            />
          </div>
        </div>
      </div>
    </div>
  );
}
