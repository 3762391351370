import React, { useContext, useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import Pdf from "./Pdf/Pdf";
import { NumericFormat } from "react-number-format";
import LivePl from "./LivePl/LivePl";
import SocketContect from "../../../../Healer/SocketContect";
import moment from "moment";
export default function SummaryCard(props) {
  const socket = useContext(SocketContect);
  const item = props.item;

  const [total, setTotal] = useState(0);

  const [btotal, setBTotal] = useState(0);

  let profit_loss_day = parseFloat(item.profit_loss_day).toFixed(0);
  let profit_loss_week = parseFloat(item.profit_loss_week).toFixed(0);


  let m2m = parseFloat(parseFloat(total) + parseFloat(btotal)).toFixed(0);

  let sharing_day = parseFloat(item.sharing_day).toFixed(0);
  let sharing_week = parseFloat(item.sharing_week).toFixed(0);

  return (
    <div
      key={props.id}
      style={{
        height: "55px",
        width: "100%",
        backgroundColor: Dark.background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 12,
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
      }}
    >
      {item.standing.length > 0
        ? item.standing.map((i) => {
            return (
              <LivePl
                item={i}
                key={i.id}
                total={item.standing.length}
              />
            );
          })
        : null}

      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
          flexDirection: "column",
        }}
      >
        <div>{item.username}</div>
        <div style={{ fontSize: 12, paddingTop: 3 }}>({item.name})</div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: profit_loss_day > 0 ? Dark.buy : Dark.sell,
        }}
      >
        <NumericFormat
          value={profit_loss_day}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        ₹
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: profit_loss_week > 0 ? Dark.buy : Dark.sell,
        }}
      >
        <NumericFormat
          value={profit_loss_week}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        ₹
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: sharing_day > 0 ? Dark.buy : Dark.sell,
        }}
      >
        <NumericFormat
          value={sharing_day}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        ₹ ({item.sharing})%
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: sharing_week > 0 ? Dark.buy : Dark.sell,
        }}
      >
        <NumericFormat
          value={sharing_week}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        ₹ ({item.sharing})%
      </div>

      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {item.standing.length > 0 ? (
          <PDFDownloadLink
            style={{
              textDecoration: "none",
            }}
            document={
              <Pdf
                profit_loss_week={item.profit_loss_week}
                sharing={item.sharing}
                sharing_day={sharing_day}
                sharing_week={sharing_week}
                profit_loss={total}
                m2m={m2m}
                btotal={btotal}
                socket={socket}
                username={item.username}
                name={item.name}
                start={moment().format("DD-MM-YYYY")}
                end={moment().format("DD-MM-YYYY")}
                standing={item.standing}
              />
            }
            fileName={`${item.username}(${item.name}) `}
          >
            <div
              style={{
                color: Dark.text,
                fontSize: 14,
                backgroundColor: Dark.primary,
                width: 100,
                height: 35,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
              }}
            >
              STANDING
            </div>
          </PDFDownloadLink>
        ) : (
          <div
            style={{
              color: Dark.text,
              fontSize: 12,
              backgroundColor: Dark.sell,
              width: 100,
              height: 35,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
            }}
          >
            NO STANDING
          </div>
        )}
      </div>
    </div>
  );
}
