import React, { useEffect, useState } from "react";
import { Dark } from "../../../Theme/Theme";
import Logo from "../../../Image/Logo.png";
import LogoSub from "../../../Image/LogoSub.png";
import { InputText } from "primereact/inputtext";
import ReactLoading from "react-loading";
import { toast } from "react-toast";
import { Checkbox } from "primereact/checkbox";

import "./Login.css";

import Backend from "../../../Backend/Backend";

const backend = new Backend();
export default function Login() {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("remember") == "1") {
      setUsername(localStorage.getItem("username_rem"));
      setPassword(localStorage.getItem("password_rem"));
      setRemember(true);
    }
  }, []);

  const onSubmit = () => {
    if (username == "") {
      toast.error("Invalid Username", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else if (password == "") {
      toast.error("Invalid Password", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else {
      setLoading(true);

      let data = {
        username: username,
        password: password,
      };

      backend.login(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          localStorage.setItem("token", r.token);
          localStorage.setItem("id", r.id);
          localStorage.setItem("username", r.username);
          localStorage.setItem("AuthState", "1");

          let data_rem = {
            username: username,
            password: password,
          };
          if (remember) {
            localStorage.setItem("remember", "1");
            localStorage.setItem("username_rem", data_rem.username);
            localStorage.setItem("password_rem", data_rem.password);
          } else {
            localStorage.setItem("remember", "0");
            localStorage.setItem("username_rem", data_rem.username);
            localStorage.setItem("password_rem", data_rem.password);
          }
          toast.success(r.message, {
            backgroundColor: Dark.buy,
            color: Dark.text,
          });

          window.location = "/";
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      });
    }
  };

  onkeydown = (event) => {
    if (event.key == "Enter") {
      event.preventDefault();
      onSubmit();
    }
  };

  return (
    <div
      style={{
        height: window.innerHeight - 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="loginview">
        <div
          style={{
            flex: 1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img src={Logo} style={{ height: "150px",marginBottom:20 }} />
          <img src={LogoSub} style={{ height: "30px",width:"250px" }} />
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >

          <div className="loginInputDark">
            <div
              style={{
                color: Dark.text,
                marginBottom: 10,
                textAlign: "center",
                fontSize: 18,
              }}
            >
              USERNAME
            </div>
            <InputText
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              style={{
                width: "250px",
              }}
              placeholder="Enter Username"
              autocomplete="new-password"
            />
          </div>
          <div className="loginInputDark">
            <div
              style={{
                color: Dark.text,
                marginBottom: 10,
                textAlign: "center",
                fontSize: 18,
              }}
            >
              PASSWORD
            </div>
            <InputText
              value={password}
              autoComplete="off"
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              style={{
                width: "250px",
              }}
              placeholder="Enter Password"
              autocomplete="new-password"
            />
          </div>
          <div
            style={{
              width: "70%",
            }}
          >
            <div
              style={{
                color: Dark.text,
                marginBottom: 10,
                width: "80%",
                fontSize: 18,
              }}
            >
              <Checkbox
                checked={remember}
                onChange={(e) => setRemember(e.checked)}
              />{" "}
              Remember
            </div>
          </div>

          <div onClick={loading ? null : onSubmit} className="loginbutton">
            {loading ? (
              <ReactLoading
                type={"spin"}
                color={Dark.text}
                height={18}
                width={18}
              />
            ) : (
              "SUBMIT"
            )}
          </div>

          <div
            style={{
              fontSize: 10,
              color: Dark.text,
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            THIS APPLICATION IS FOR TRAINING PURPOSE ONLY.
          </div>
        </div>
      </div>
    </div>
  );
}
