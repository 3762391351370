import React, { useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import { InputText } from "primereact/inputtext";
import { IoSearch } from "react-icons/io5";
export default function Total(props) {
  const pending = props.pending;

  const [total, setTotal] = useState(0);

  const [limit, setLimit] = useState(0);
  const [sl, setSl] = useState(0);

  const [search, setSearch] = useState("");

  useEffect(() => {
    Filter();
  }, [pending]);

  const Filter = () => {
    if (pending.length > 0) {
      var limit = pending.filter((x) => x.limit_sl == 0);
      var sl = pending.filter((x) => x.limit_sl == 1);

      setTotal(pending.length);
      setLimit(limit.length);
      setSl(sl.length);
    }
  };

  const searchSymbol = (a) => {
    if (a.length > 1) {
      var data = pending.filter((obj) =>
        Object.keys(obj).some((key) =>
          obj[key] == null
            ? null
            : obj[key]
                .toString()
                .toLowerCase()
                .includes(a.toString().toLowerCase())
        )
      );
      props.Update(data);
    } else {
      props.Update(pending);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        height: 45,
        backgroundColor: Dark.primary,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: 10,
            backgroundColor: Dark.background,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 10,
            color: Dark.buy,
          }}
        >
          LIMIT : {limit}
        </div>
      </div>

      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: 10,
            backgroundColor: Dark.background,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 10,
            color: Dark.text,
          }}
        >
          TOTAL : {total}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: 10,
            backgroundColor: Dark.background,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 10,
            color: Dark.sell,
          }}
        >
          STOP-LOSS : {sl}
        </div>
      </div>

      <div style={{ marginRight: 20 }}>
        <div
          style={{
            marginRight: 10,
            width: "250px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            paddingRight: 10,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IoSearch style={{ color: Dark.text, fontSize: 22 }} />
          </div>
          <div>
            <InputText
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                searchSymbol(e.target.value);
              }}
              style={{
                height: 40,
                backgroundColor: Dark.background,
                background: Dark.background,
                borderStyle: "none",
                width: "180px",
                color: Dark.text,
              }}
              placeholder="Search "
            />
          </div>
        </div>
      </div>
    </div>
  );
}
