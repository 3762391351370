import React, { useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import { InputText } from "primereact/inputtext";
import Loading from "react-loading";
import "./Options.css";
import { Dialog } from "primereact/dialog";
import TPassword from "../../../../../Healer/TPassword/TPassword";
const backend = new Backend();

export default function OptionsCard(props) {
  const i = props.item;
  const master = props.master;
  const [loading, setLoading] = useState("");

  const [qty_per_order, setQty_per_order] = useState("");
  const [total_quantity, setTotal_quantity] = useState("");

  const [edit, setEdit] = useState(false);

  const [show_edit, setShowEdit] = useState(false);
  const [show_reset, setShowReset] = useState(false);
  const [show_block, setShowBlock] = useState(false);
  const [tpassword, setTPassword] = useState("");

  const EditOptionsSymbol = () => {
    setShowEdit(false);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      
      master_id: master.id,
      symbol: i.symbol_display,
      min_qty: "0",
      qty_per_order: qty_per_order == "" ? i.qty_per_order : qty_per_order,
      total_quantity: total_quantity == "" ? i.total_quantity : total_quantity,
      tpassword: tpassword,
    };

    backend.master_edit_qty(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.error(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        props.reload();
        setEdit(false);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const Block = () => {
    setShowBlock(false);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      
      master_id: master.id,
      symbol: i.symbol_display,
      expiry_date: i.expiry_date,
      tpassword: tpassword,
    };

    backend.master_block_add(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        props.reload();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };
  const Reset = () => {
    setShowReset(false);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      
      master_id: master.id,
      symbol: i.symbol_display,
      tpassword: tpassword,
    };

    backend.master_reset_qty(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        props.reload();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        height: 80,
        width: "100%",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 0.2,
        borderBottomColor: Dark.primary,
        display: "flex",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {i.symbol}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 14, marginBottom: 7 }}>
          {parseFloat(i.qty_per_order).toFixed(2)}
        </div>

        {edit ? (
          <div className="InputDark">
            <InputText
              value={qty_per_order}
              onChange={(e) => {
                setQty_per_order(e.target.value);
              }}
              placeholder="Enter Order Qty"
              autocomplete="new-password"
            />
          </div>
        ) : null}
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 14, marginBottom: 7 }}>
          {parseFloat(i.total_quantity).toFixed(2)}
        </div>

        {edit ? (
          <div className="InputDark">
            <InputText
              value={total_quantity}
              onChange={(e) => {
                setTotal_quantity(e.target.value);
              }}
              placeholder="Enter Total Order"
              autocomplete="new-password"
            />
          </div>
        ) : null}
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        {i.edit == 1 ? (
          <div
            onClick={() => setShowReset(true)}
            style={{
              padding: 10,
              backgroundColor: Dark.sell,
              borderRadius: 7,
            }}
          >
            {loading ? (
              <Loading type="spin" height={25} width={25} color={Dark.text} />
            ) : (
              "RESET"
            )}
          </div>
        ) : i.block == 1 ? (
          <div
            style={{
              padding: 10,
              backgroundColor: Dark.sell,
              borderRadius: 7,
            }}
          >
            BLOCK
          </div>
        ) : (
          <div
            onClick={() => (edit ? setShowEdit(true) : setEdit(true))}
            style={{
              padding: 10,
              backgroundColor: Dark.primary,
              borderRadius: 7,
            }}
          >
            {loading ? (
              <Loading type="spin" height={25} width={25} color={Dark.text} />
            ) : edit ? (
              "SAVE"
            ) : (
              "EDIT"
            )}
          </div>
        )}

        {i.block == 1 ? null : (
          <div
            onClick={() => setShowBlock(true)}
            style={{ padding: 10, backgroundColor: Dark.sell, borderRadius: 7 }}
          >
            {loading ? (
              <Loading type="spin" height={25} width={25} color={Dark.text} />
            ) : (
              "BLOCKED"
            )}
          </div>
        )}
      </div>

      <Dialog
        visible={show_block}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setShowBlock(false)}
      >
        <TPassword Update={Block} tpassword={(a) => setTPassword(a)} />
      </Dialog>
      <Dialog
        visible={show_edit}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setShowEdit(false)}
      >
        <TPassword
          Update={EditOptionsSymbol}
          tpassword={(a) => setTPassword(a)}
        />
      </Dialog>
      <Dialog
        visible={show_reset}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setShowReset(false)}
      >
        <TPassword Update={Reset} tpassword={(a) => setTPassword(a)} />
      </Dialog>
    </div>
  );
}
