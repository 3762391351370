import React, { useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import moment from "moment";
import TPassword from "../../../../Healer/TPassword/TPassword";
import { Dialog } from "primereact/dialog";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";

const backend = new Backend();
export default function TradeCard(props) {
  const i = props.item;
  let m2m = i.profit_loss + i.brokerage_client_amt;

  const [tpassword, setTPassword] = useState("");

  const [showdelete, setShowDelete] = useState(false);

  const Delete = () => {
    setShowDelete(false);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      client_id: i.client_id,
      trade_id: i.id,
      tpassword: tpassword,
    };

    backend.delete_trade(data).then((r) => {
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        props.load();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };
  return (
    <div
      style={{
        height: 50,
        display: "flex",
        backgroundColor: Dark.background,
        justifyContent: "center",
        alignItems: "center",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
        
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
          textTransform:"uppercase"
        }}
      >
        {i.master_username}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
          textTransform:"uppercase"
        }}
      >
        {i.client_username}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1.5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
          flexDirection: "column",
        }}
      >
        <div>{i.symbol}</div>
        <div style={{ fontSize: 10, paddingTop: 5 }}>
          {moment(i.expiry_date).format("DD-MMM")}
        </div>
      </div>
      <div
        style={{
          color: i.buy_sell == 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.buy_sell == 0 ? "BUY" : "SELL"}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.qty}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.rate}
      </div>
      <div
        style={{
          color:
            i.type == "NEW SAUDA"
              ? Dark.primary
              : i.type == "SAUDA ADDED"
              ? Dark.buy
              : i.type == "SAUDA REMOVE"
              ? Dark.sell
              : i.type == "SAUDA SQ.Off"
              ? Dark.sell
              : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: 14,
        }}
      >
        {i.type}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: 14,
        }}
      >
        {i.new_deposit}
      </div>
      <div
        style={{
          color: m2m > 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.type == "NEW SAUDA" || i.type == "SAUDA ADDED"
          ? "0"
          : parseFloat(m2m).toFixed(2)}
      </div>
      <div
        style={{
          color: Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.brokerage_client_amt}
      </div>
      <div
        style={{
          color: i.profit_loss > 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.profit_loss}
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 13,
          textAlign:"center"
        }}
      >
        {i.other == null ? "-" : i.other}
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <div>{moment(i.date_created).format("DD-MM-YYYY")}</div>
        <div>{moment(i.date_created).format("HH:mm:ss")}</div>
      </div>


      <div
        onClick={() => setShowDelete(true)}
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        <div
          style={{
            padding: 10,
            backgroundColor: Dark.sell,
            color: Dark.text,
            borderRadius: 10,
          }}
        >
          DELETE
        </div>
      </div>


      <Dialog
        visible={showdelete}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setShowDelete(false)}
      >
        <TPassword
          Update={Delete}
          tpassword={(a) => setTPassword(a)}
        />
      </Dialog>
    </div>
  );
}
