import React, { useEffect, useState } from "react";
import { Dark } from "../../../Theme/Theme";
import TopCard from "./TopCard/TopCard";

import Menu from "./Menu";
import PendingCard from "./PendingCard/PendingCard";
import Backend from "../../../Backend/Backend";
import { toast } from "react-toast";
import Loading from "react-loading";
import Total from './Total'

const backend = new Backend();

export default function Pending() {
  const [loading, setLoading] = useState(false);

  const [key, setKey] = useState(false);

  const [pending, setPending] = useState([]);
  const [pendingsearch, setPendingSearch] = useState([]);
  const [limit, setLimit] = useState(0);
  const [sl, setSl] = useState(0);
  const [total, setTotal] = useState(0);
  const [master_id, setMasterId] = useState("");
  const [client_id, setClientId] = useState("");
  const [symbol, setSymbol] = useState("");
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());

  useEffect(() => {
    loadPending();
  }, []);

  const loadPending = (a) => {
    setLoading(true);

    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      
      start: start,
      end: end,
    };

    backend.load_pending(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        var pendings = [];
        if (r.pending.length > 0) {
          r.pending.map((i, t) => {
            if (t < 100) {
              pendings.push(i);
            }
          });
        }
        setPending(pendings);
        setPendingSearch(r.pending);
        setLimit(r.limit);
        setSl(r.sl);
        setTotal(r.pendinglimit);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadMasterPending = (a) => {
    setMasterId(a);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      
      master_id: a,
      client_id: client_id,
      symbol: symbol,
      start: start,
      end: end,
    };
    backend.load_master_pending(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        var pendings = [];
        if (r.pending.length > 0) {
          r.pending.map((i, t) => {
            if (t < 100) {
              pendings.push(i);
            }
          });
        }
        setPending(pendings);
        setPendingSearch(r.pending);
        setLimit(r.limit);
        setSl(r.sl);
        setTotal(r.pendinglimit);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadClientPending = (a) => {
    setClientId(a);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      
      master_id: master_id,
      client_id: a,
      symbol: symbol,
      start: start,
      end: end,
    };
    backend.load_client_pending(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        var pendings = [];
        if (r.pending.length > 0) {
          r.pending.map((i, t) => {
            if (t < 100) {
              pendings.push(i);
            }
          });
        }
        setPending(pendings);
        setPendingSearch(r.pending);
        setLimit(r.limit);
        setSl(r.sl);
        setTotal(r.pendinglimit);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadSymbolPending = (a) => {
    setSymbol(a);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      
      master_id: master_id,
      client_id: client_id,
      symbol: a,
      start: start,
      end: end,
    };
    backend.load_symbol_pending(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        var pendings = [];
        if (r.pending.length > 0) {
          r.pending.map((i, t) => {
            if (t < 100) {
              pendings.push(i);
            }
          });
        }
        setPending(pendings);
        setPendingSearch(r.pending);
        setLimit(r.limit);
        setSl(r.sl);
        setTotal(r.pendinglimit);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadPendingFilter = (a, b) => {
    setStart(a);
    setEnd(b);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      
      master_id: master_id,
      client_id: client_id,
      symbol: symbol,
      start: a,
      end: b,
    };

    backend.load_pending(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        var pendings = [];
        if (r.pending.length > 0) {
          r.pending.map((i, t) => {
            if (t < 100) {
              pendings.push(i);
            }
          });
        }
        setPending(pendings);
        setPendingSearch(r.pending);
        setLimit(r.limit);
        setSl(r.sl);
        setTotal(r.pendinglimit);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadMore = () => {
    var total = pending.length;

    var lower = total - 1;
    var upper = total + 100;
    var pendings = [];

    if (pendingsearch.length > 0) {
      pendingsearch.map((i, t) => {
        if (t > lower && t < upper) {
          pendings.push(i);
        }
      });
    }

    setPending([...pending, ...pendings]);
  };

  const handleScroll = (e) => {
    if (
      Math.abs(
        e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop
      ) < 1
    ) {
      loadMore();
    }
  };

  const Update = (a) => {
    setPending(a);
  };

  const sort = (s) => {
    setKey(!key);

    let data;

    if (s == "ClientUp") {
      data = pendingsearch.sort((a, b) =>
        a.client_username > b.client_username ? 1 : -1
      );
    } else if (s == "ClientDown") {
      data = pendingsearch.sort((a, b) =>
        a.client_username < b.client_username ? 1 : -1
      );
    } else if (s == "SymbolUp") {
      data = pendingsearch.sort((a, b) => (a.symbol > b.symbol ? 1 : -1));
    } else if (s == "SymbolDown") {
      data = pendingsearch.sort((a, b) => (a.symbol < b.symbol ? 1 : -1));
    } else if (s == "BuySellUp") {
      data = pendingsearch.sort((a, b) => (a.buy_sell > b.buy_sell ? 1 : -1));
    } else if (s == "BuySellDown") {
      data = pendingsearch.sort((a, b) => (a.buy_sell < b.buy_sell ? 1 : -1));
    } else if (s == "QtyUp") {
      data = pendingsearch.sort((a, b) => (a.qty > b.qty ? 1 : -1));
    } else if (s == "QtyDown") {
      data = pendingsearch.sort((a, b) => (a.qty < b.qty ? 1 : -1));
    } else if (s == "RateUp") {
      data = pendingsearch.sort((a, b) => (a.rate > b.rate ? 1 : -1));
    } else if (s == "RateDown") {
      data = pendingsearch.sort((a, b) => (a.rate < b.rate ? 1 : -1));
    } else if (s == "LimitUp") {
      data = pendingsearch.sort((a, b) => (a.high_mid > b.high_mid ? 1 : -1));
    } else if (s == "LimitDown") {
      data = pendingsearch.sort((a, b) => (a.high_mid < b.high_mid ? 1 : -1));
    } else if (s == "TypeUp") {
      data = pendingsearch.sort((a, b) => (a.limit_sl > b.limit_sl ? 1 : -1));
    } else if (s == "TypeDown") {
      data = pendingsearch.sort((a, b) => (a.limit_sl < b.limit_sl ? 1 : -1));
    } else if (s == "MsgUp") {
      data = pendingsearch.sort((a, b) => (a.msg > b.msg ? 1 : -1));
    } else if (s == "MsgDown") {
      data = pendingsearch.sort((a, b) => (a.msg < b.msg ? 1 : -1));
    } else if (s == "DateUp") {
      data = pendingsearch.sort((a, b) =>
        a.date_created > b.date_created ? 1 : -1
      );
    } else if (s == "DateDown") {
      data = pendingsearch.sort((a, b) =>
        a.date_created < b.date_created ? 1 : -1
      );
    } else {
      setPending([]);
    }

    setPending(data);
  };

  return (
    <div
      style={{
        display: "flex",
        height: "82vh",
        flexDirection: "column",
      }}
    >
      <TopCard
        pending={pending}
        limit={limit}
        sl={sl}
        total={total}
        start={start}
        end={end}
        master_id={master_id}
        client_id={client_id}
        symbol_id={symbol}
        reload={loadPending}
        master={loadMasterPending}
        client={loadClientPending}
        symbol={loadSymbolPending}
        filter={loadPendingFilter}
      />
      <div style={{ backgroundColor: Dark.background }}>
        <Total pending={pendingsearch} Update={Update} />
        <Menu sort={sort} />
        <div
          onScroll={handleScroll}
          className="scroll"
          style={{ height: "62vh", overflowY: "scroll" }}
        >
          {loading ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loading type="spin" height={35} width={35} color={Dark.text} />
            </div>
          ) : pending.length > 0 ? (
            pending.map((i, t) => {
              return <PendingCard item={i} index={t} load={loadPending} />;
            })
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
                {" "}
                Nothing here
              </div>
              <div style={{ color: Dark.text }}>No Pending Found.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
