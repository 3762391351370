import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dark } from "../../../../Theme/Theme";
import { RiFileList3Line } from "react-icons/ri";
import { IoMdAddCircle } from "react-icons/io";
import { BiFilterAlt } from "react-icons/bi";
import { BsFilePdf } from "react-icons/bs";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import MasterCard from "./MasterCard";
import "./Master.css";
import Loading from "react-loading";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MasterPdf from "./MasterPdf/MasterPdf";

const backend = new Backend();

export default function Master(props) {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState("");
  const [masterlist, setMasterList] = useState([]);
  const [mastersearch, setMasterSearch] = useState([]);

  const [keys, setkey] = useState(false);

  useEffect(() => {
    loadMaster();
  }, []);

  const loadMaster = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      
    };

    backend.load_master(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setMasterList(r.master);
        setMasterSearch(r.master);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const searchSymbol = (a) => {
    if (a.length > 1) {
      var data = mastersearch.filter((obj) =>
        Object.keys(obj).some((key) =>
          obj[key].toString().toLowerCase().includes(a.toString().toLowerCase())
        )
      );
      setMasterList(data);
    } else {
      setMasterList(mastersearch);
    }
  };

  const stort = (a) => {
    if (masterlist.length > 0) {
      if (a == "1") {
        let data = mastersearch.sort(
          (a, b) => parseFloat(a.status) - parseFloat(b.status)
        );
        setMasterList(data);
      } else if (a == "2") {
        let data = mastersearch.sort((a, b) =>
          a.username.localeCompare(b.username)
        );
        setMasterList(data);
      } else if (a == "3") {
        let data = mastersearch.sort((a, b) =>
          b.username.localeCompare(a.username)
        );
        setMasterList(data);
      } else {
        toast.error("No Options Found", {
          backgroundColor: Dark.primary,
          color: Dark.text,
        });
      }
    }
  };

  return (
    <div>
      <div
        style={{
          height: 50,
          backgroundColor: Dark.secondary,
          marginRight: 10,
          marginLeft: 10,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <div
          onClick={props.ShowSummary}
          style={{
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 50,
          }}
        >
          <RiFileList3Line size={27} color={Dark.text} />
        </div>
        <div style={{ flex: 1, padding: 10 }}>
          <InputText
            className="loginInputAddSearchNew"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              searchSymbol(e.target.value);
            }}
            style={{
              height: 40,
              backgroundColor: Dark.background,
              borderStyle: "none",
              width: "120px",
              color: Dark.text,
            }}
            placeholder="Search Master,Username"
          />
        </div>
        <div
          style={{
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 50,
          }}
        >
          <div class="dropdownfilter">
            <button class="dropbtnfilter">
              <BiFilterAlt size={30} color={Dark.primary} />
            </button>
            <div class="dropdown-contentfilter">
              <div
                className="link"
                onClick={() => {
                  stort("1");
                  setkey(!keys);
                }}
              >
                ACTIVE
              </div>
              <div
                className="link"
                onClick={() => {
                  stort("2");
                  setkey(!keys);
                }}
              >
                A-Z
              </div>
              <div
                className="link"
                onClick={() => {
                  stort("3");
                  setkey(!keys);
                }}
              >
                Z-A
              </div>
            </div>
          </div>
        </div>

        <div
          onClick={props.Add}
          style={{
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 50,
          }}
        >
          <IoMdAddCircle size={35} color={Dark.buy} />
        </div>

        {masterlist.length > 0 ? (
          <div
            style={{
              height: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 50,
            }}
          >
            <PDFDownloadLink
              style={{
                textDecoration: "none",
              }}
              document={<MasterPdf master={masterlist} />}
              fileName={"Master-List"}
            >
              {({ blob, url, loading, error }) => (
                <div style={{ color: Dark.text }}>
                  <BsFilePdf size={30} color={Dark.text} />
                </div>
              )}
            </PDFDownloadLink>
          </div>
        ) : null}
      </div>

      <div
        key={keys}
        className="scroll"
        style={{
          margin: 10,
          height: "69vh",
          overflowY: "scroll",
        }}
      >
        {loading ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading type="spin" color={Dark.text} height={35} width={35} />
          </div>
        ) : masterlist.length > 0 ? (
          <div>
            {masterlist.map((i) => {
              return (
                <MasterCard
                  item={i}
                  master={props.master}
                  setMaster={(a) => props.setMaster(a)}
                />
              );
            })}
          </div>
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: Dark.text,
            }}
          >
            NO MASTER FOUND.
          </div>
        )}
      </div>
    </div>
  );
}
