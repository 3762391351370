import React, { useContext, useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import Pdf from "./Pdf/Pdf";
import { NumericFormat } from "react-number-format";
import LivePl from "./LivePl/LivePl";
import SocketContect from "../../../../Healer/SocketContect";
import moment from "moment";
export default function SummaryCard(props) {
  const socket = useContext(SocketContect);
  const item = props.item;

  const [total, setTotal] = useState(0);

  const [btotal, setBTotal] = useState(0);

  const [pfdata, setPfdata] = useState([]);

  let profit_loss_day = parseFloat(item.profit_loss_day).toFixed(0);
  let profit_loss_week = parseFloat(item.profit_loss_week).toFixed(0);
  let deposit_total = parseFloat(item.deposit_total).toFixed(0);
  let deposit_current = parseFloat(item.deposit_current).toFixed(0);

  const LivePlTotal = (a, b, c, d) => {
    if (item.standing.length > 0) {
      var newpfdata = pfdata;

      if (Array.isArray(newpfdata)) {
        var found = false;

        newpfdata.map((i1, t1) => {
          if (i1.id == a) {
            found = t1;
          }
        });

        if (found === false) {
          newpfdata.push({
            id: a,
            pf: b,
            btotal: c,
          });

          var totals = 0;
          var btotalnews = 0;
          newpfdata.map((i3) => {
            total = parseFloat(totals) + parseFloat(i3.pf);
            btotalnew = parseFloat(btotalnews) + parseFloat(i3.btotal);
          });
          setPfdata(newpfdata);
          setTotal(total);
          setBTotal(btotalnew);
        } else {
          newpfdata[found].pf = b;
          newpfdata[found].btotal = c;
          var total = 0;
          var btotalnew = 0;
          newpfdata.map((i) => {
            total = parseFloat(total) + parseFloat(i.pf);
            btotalnew = parseFloat(btotalnew) + parseFloat(i.btotal);
          });

          setPfdata(newpfdata);
          setTotal(total);
          setBTotal(btotalnew);
        }
      } else {
        newpfdata.push({
          id: a,
          pf: b,
          btotal: c,
        });
        setPfdata(newpfdata);
        setTotal(b);
        setBTotal(c);
      }
    }
  };

  let m2m = parseFloat(parseFloat(total) + parseFloat(btotal)).toFixed(0);

  return (
    <div
      key={props.id}
      style={{
        height: "55px",
        width: "100%",
        backgroundColor: Dark.background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 12,
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
      }}
    >
      {item.standing.length > 0
        ? item.standing.map((i) => {
            return (
              <LivePl
                item={i}
                key={i.id}
                total={item.standing.length}
                LivePlTotal={LivePlTotal}
              />
            );
          })
        : null}

      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
          flexDirection: "column",
        }}
      >
        <div>{item.username}</div>
        <div style={{ fontSize: 12, paddingTop: 3 }}>({item.name})</div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: profit_loss_day > 0 ? Dark.buy : Dark.sell,
        }}
      >
        <NumericFormat
          value={profit_loss_day}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        ₹
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: profit_loss_week > 0 ? Dark.buy : Dark.sell,
        }}
      >
        <NumericFormat
          value={profit_loss_week}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        ₹
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        <NumericFormat
          value={deposit_total}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        ₹
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        <NumericFormat
          value={deposit_current}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        ₹
      </div>

      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {item.standing.length > 0 ? (
          <PDFDownloadLink
            style={{
              textDecoration: "none",
            }}
            document={
              <Pdf
                profit_loss_week={item.profit_loss_week}
                deposit_total={item.deposit_total}
                deposit_current={item.deposit_current}
                profit_loss={total}
                m2m={m2m}
                btotal={btotal}
                socket={socket}
                username={item.username}
                name={item.name}
                start={moment().format("DD-MM-YYYY")}
                end={moment().format("DD-MM-YYYY")}
                standing={item.standing}
              />
            }
            fileName={`${item.username}(${item.name}) `}
          >
            <div
              style={{
                color: Dark.text,
                fontSize: 14,
                backgroundColor: Dark.primary,
                width: 100,
                height: 35,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
              }}
            >
              STANDING
            </div>
          </PDFDownloadLink>
        ) : (
          <div
            style={{
              color: Dark.text,
              fontSize: 12,
              backgroundColor: Dark.sell,
              width: 100,
              height: 35,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
            }}
          >
            NO STANDING
          </div>
        )}
      </div>

      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => {
            props.name(item.username);
            props.clientstanding(item.standing);
            props.show();
          }}
          style={{
            color:
              item.standing.length > 0
                ? total > 0
                  ? Dark.buy
                  : Dark.sell
                : Dark.text,
            fontSize: item.standing.length > 0 ? 16 : 12,
            backgroundColor: Dark.secondary,
            width: 100,
            height: 35,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            flexDirection:"column"
          }}
        >
          <div>
            {item.standing.length > 0 ? (
              <NumericFormat
                value={total.toFixed(0)}
                displayType={"text"}
                thousandSeparator={true}
              />
            ) : (
              "NO STANDING"
            )}
            {item.standing.length > 0 ? "₹" : null}
          </div>
          <div style={{color:Dark.text,fontSize:10,textTransform:"uppercase"}} >View</div>
        </div>
      </div>
    </div>
  );
}
