import React, { useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import { TbReportAnalytics } from "react-icons/tb";
import { Dialog } from "primereact/dialog";
import Filter from "./Filter/Filter";
import Broker from "./Broker/Broker";
import Pdf from "./Pdf/Pdf";
import moment from "moment";
import { NumericFormat } from "react-number-format";

export default function TopCard(props) {
  let pl = parseFloat(props.pl == null ? 0 : props.pl).toFixed(0);
  let brokerage = parseFloat(
    props.brokerage == null ? 0 : props.brokerage
  ).toFixed(0);
  let brokerage_broker = parseFloat(
    props.brokerage_broker == null ? 0 : props.brokerage_broker
  ).toFixed(0);

  let future = parseFloat(props.future == null ? 0 : props.future).toFixed(0);
  let mcx = parseFloat(props.mcx == null ? 0 : props.mcx).toFixed(0);
  let options = parseFloat(props.options == null ? 0 : props.options).toFixed(
    0
  );

  let futurebroker = parseFloat(
    props.futurebroker == null ? 0 : props.futurebroker
  ).toFixed(0);
  let mcxbroker = parseFloat(
    props.mcxbroker == null ? 0 : props.mcxbroker
  ).toFixed(0);
  let optionebroker = parseFloat(
    props.optionebroker == null ? 0 : props.optionebroker
  ).toFixed(0);

  const [filter, setFilter] = useState(false);
  const [broker, setBroker] = useState(false);

  const [gross, setGross] = useState(0);
  const [sharing, setSharing] = useState(0);
  const [netamount, setNetAmount] = useState(0);

  const [selectbroker, setSelectBroker] = useState("");
  const [filtertype, setFilterType] = useState("");

  useEffect(() => {
    total();
  }, [props.client]);

  const total = () => {
    if (props.client.length > 0) {
      let gross = 0;
      let sharing = 0;
      let netamount = 0;

      props.client.map((i) => {
        gross = parseFloat(gross) + parseFloat(i.gross);
        sharing = parseFloat(sharing) + parseFloat(i.sharing);
        netamount = parseFloat(netamount) + parseFloat(i.netamount);
      });

      setGross(gross);
      setSharing(sharing);
      setNetAmount(netamount);
    }
  };

  const clearclient = () => {
    setSelectBroker("");
    props.broker("");
  };
  const clearfilter = () => {
    setFilterType("");
    props.filter(new Date(), new Date());
  };

  return (
    <div
      style={{
        height: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flexDirection: "column",
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 2,
            borderRightColor: Dark.primary,
          }}
        >
          <div>TOTAL</div>

          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 10 }}>
              <div
                style={{
                  textTransform: "uppercase",
                  fontSize: 12,
                  marginTop: 10,
                }}
              >
                P/L
              </div>
              <div
                style={{
                  textTransform: "uppercase",
                  fontSize: 14,
                  marginTop: 10,
                  color: pl > 0 ? Dark.buy : Dark.sell,
                }}
              >
                <NumericFormat
                  value={pl}
                  displayType={"text"}
                  thousandSeparator={true}
                />
                ₹
              </div>
            </div>

            <div style={{ marginRight: 10 }}>
              <div
                style={{
                  textTransform: "uppercase",
                  fontSize: 12,
                  marginTop: 10,
                }}
              >
                Brokerage
              </div>
              <div
                style={{
                  textTransform: "uppercase",
                  fontSize: 14,
                  marginTop: 10,
                  color: Dark.sell,
                }}
              >
                <NumericFormat
                  value={brokerage}
                  displayType={"text"}
                  thousandSeparator={true}
                />
                ₹
              </div>
            </div>

            <div>
              <div
                style={{
                  textTransform: "uppercase",
                  fontSize: 12,
                  marginTop: 10,
                }}
              >
                Broker
              </div>
              <div
                style={{
                  textTransform: "uppercase",
                  fontSize: 14,
                  marginTop: 10,
                  color: Dark.sell,
                }}
              >
                <NumericFormat
                  value={brokerage_broker}
                  displayType={"text"}
                  thousandSeparator={true}
                />
                ₹
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flexDirection: "column",
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 2,
            borderRightColor: Dark.primary,
          }}
        >
          <div>FUTURE</div>

          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 10 }}>
              <div
                style={{
                  textTransform: "uppercase",
                  fontSize: 12,
                  marginTop: 10,
                }}
              >
                Brokerage
              </div>
              <div
                style={{
                  textTransform: "uppercase",
                  fontSize: 14,
                  marginTop: 10,
                  color: Dark.sell,
                }}
              >
                <NumericFormat
                  value={future}
                  displayType={"text"}
                  thousandSeparator={true}
                />
                ₹
              </div>
            </div>

            <div>
              <div
                style={{
                  textTransform: "uppercase",
                  fontSize: 12,
                  marginTop: 10,
                }}
              >
                Broker
              </div>
              <div
                style={{
                  textTransform: "uppercase",
                  fontSize: 14,
                  marginTop: 10,
                  color: Dark.sell,
                }}
              >
                <NumericFormat
                  value={futurebroker}
                  displayType={"text"}
                  thousandSeparator={true}
                />
                ₹
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flexDirection: "column",
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 2,
            borderRightColor: Dark.primary,
          }}
        >
          <div>Mcx</div>

          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 10 }}>
              <div
                style={{
                  textTransform: "uppercase",
                  fontSize: 12,
                  marginTop: 10,
                }}
              >
                Brokerage
              </div>
              <div
                style={{
                  textTransform: "uppercase",
                  fontSize: 14,
                  marginTop: 10,
                  color: Dark.sell,
                }}
              >
                <NumericFormat
                  value={mcx}
                  displayType={"text"}
                  thousandSeparator={true}
                />
                ₹
              </div>
            </div>

            <div>
              <div
                style={{
                  textTransform: "uppercase",
                  fontSize: 12,
                  marginTop: 10,
                }}
              >
                Broker
              </div>
              <div
                style={{
                  textTransform: "uppercase",
                  fontSize: 14,
                  marginTop: 10,
                  color: Dark.sell,
                }}
              >
                <NumericFormat
                  value={mcxbroker}
                  displayType={"text"}
                  thousandSeparator={true}
                />
                ₹
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flexDirection: "column",
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 2,
            borderRightColor: Dark.primary,
          }}
        >
          <div>Options</div>

          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 10 }}>
              <div
                style={{
                  textTransform: "uppercase",
                  fontSize: 12,
                  marginTop: 10,
                }}
              >
                Brokerage
              </div>
              <div
                style={{
                  textTransform: "uppercase",
                  fontSize: 14,
                  marginTop: 10,
                  color: Dark.sell,
                }}
              >
                <NumericFormat
                  value={options}
                  displayType={"text"}
                  thousandSeparator={true}
                />
                ₹
              </div>
            </div>

            <div>
              <div
                style={{
                  textTransform: "uppercase",
                  fontSize: 12,
                  marginTop: 10,
                }}
              >
                Broker
              </div>
              <div
                style={{
                  textTransform: "uppercase",
                  fontSize: 14,
                  marginTop: 10,
                  color: Dark.sell,
                }}
              >
                <NumericFormat
                  value={optionebroker}
                  displayType={"text"}
                  thousandSeparator={true}
                />
                ₹
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 0.7,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "60px",
            width: "140px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div
            onClick={clearclient}
            style={{
              color:
                props.brokerdata == null || props.brokerdata == ""
                  ? Dark.text
                  : Dark.sell,
              fontSize: 14,
            }}
          >
            {props.brokerdata == null || props.brokerdata == ""
              ? "BROKER"
              : "CLEAR"}
          </div>
          <div
            onClick={() => {
              setBroker(true);
            }}
            style={{
              color: Dark.text,
              marginTop: 10,
              fontSize: 16,
            }}
          >
            {selectbroker == null || selectbroker == ""
              ? "SEARCH"
              : selectbroker.broker_name}
          </div>
        </div>
        <div
          style={{
            height: "60px",
            width: "140px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div
            onClick={clearclient}
            style={{
              color:
                props.brokerdata == null || props.brokerdata == ""
                  ? Dark.text
                  : Dark.sell,
              fontSize: 14,
            }}
          >
            {props.brokerdata == null || props.brokerdata == ""
              ? "BROKER"
              : "CLEAR"}
          </div>
          <div
            onClick={() => {
              setBroker(true);
            }}
            style={{
              color: Dark.text,
              marginTop: 10,
              fontSize: 16,
            }}
          >
            {selectbroker == null || selectbroker == ""
              ? "SEARCH"
              : selectbroker.broker_name}
          </div>
        </div>

        <div
          style={{
            height: "60px",
            width: "140px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
            textAlign: "center",
          }}
        >
          <div
            onClick={clearfilter}
            style={{
              color: filtertype == "" ? Dark.text : Dark.sell,
              fontSize: 14,
            }}
          >
            {filtertype == "" ? "FILTER" : "CLEAR"}
          </div>
          <div
            onClick={() => {
              setFilter(true);
            }}
            style={{
              color: Dark.text,
              marginTop: 5,
              fontSize: 16,
            }}
          >
            {filtertype == "" || filtertype == "CUSTOM" ? (
              <>
                <div style={{ marginBottom: 3, fontSize: 11 }}>
                  START : {moment(props.start).format("DD-MM-YYYY")}
                </div>
                <div style={{ fontSize: 11 }}>
                  END :{moment(props.end).format("DD-MM-YYYY")}
                </div>
              </>
            ) : (
              filtertype
            )}
          </div>
        </div>

        {props.client.length > 0 ? (
          <div
            style={{
              height: "70px",
              width: "60px",
              backgroundColor: Dark.secondary,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              borderRadius: 10,
              marginLeft: 10,
            }}
          >
            <div
              style={{
                color: Dark.text,
              }}
            >
              <Pdf
                selectbroker={props.brokerdata}
                client={props.client}
                pl={props.pl}
                brokerage={props.brokerage}
                brokerage_broker={brokerage_broker}
                future={future}
                mcx={mcx}
                options={options}
                futurebroker={futurebroker}
                mcxbroker={mcxbroker}
                optionebroker={optionebroker}
                start={props.start}
                end={props.end}
                gross={gross}
                sharing={sharing}
                netamount={netamount}
              />
            </div>
          </div>
        ) : null}
      </div>
      <Dialog
        header={"Filter List"}
        visible={filter}
        style={{ width: "40vw", height: "60vh" }}
        onHide={() => setFilter(false)}
      >
        <Filter
          filter={props.filter}
          updatefilter={(a) => {
            setFilterType(a);
            setFilter(false);
          }}
          close={() => setFilter(false)}
        />
      </Dialog>

      <Dialog
        header={"Filter List"}
        visible={broker}
        style={{ width: "50vw", height: "70vh" }}
        onHide={() => setBroker(false)}
      >
        <Broker
          broker={props.broker}
          start={props.start}
          end={props.end}
          updatebroker={(a) => {
            setSelectBroker(a);
          }}
          update={(a) => {
            setBroker(false);
          }}
          close={() => setFilter(false)}
        />
      </Dialog>
    </div>
  );
}
