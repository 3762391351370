import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import AddClient from "./AddClient";
import BuySell from "./BuySell";
import EditQty from "./EditQty";
import Fund from "./Fund";
import LotVolume from "./LotVolume";
import MidLimit from "./MidLimit";
import FreshLimit from "./FreshLimit";
import Pending from "./Pending";
import SqOff from "./SqOff";
import Standing from "./Standing";
import SymbolBrokerage from "./SymbolBrokerage";
import Trade from "./Trade";
import Loading from "react-loading";
import Marquee from "./Marquee";
const backend = new Backend();
export default function Permission(props) {
  const master = props.master;

  const [masterP, setMasterP] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadMasterSingle();
  }, []);

  const loadMasterSingle = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      master_id: master.id,
    };

    backend.permission_master(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setMasterP(r.master);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {loading ? (
        <Loading type="spin" color={Dark.text} height={45} width={45} />
      ) : masterP != "" ? (
        <>
          <div
            style={{
              flex: 1,
              height: "120px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "90%",
            }}
          >
            <AddClient
              status={masterP.add_client}
              master_id={masterP.master_id}
              reload={loadMasterSingle}
            />
            <Marquee
              status={masterP.marquee}
              master_id={masterP.master_id}
              reload={loadMasterSingle}
            />
            <BuySell
              status={masterP.trade}
              master_id={masterP.master_id}
              reload={loadMasterSingle}
            />
            <EditQty
              status={masterP.edit_qty}
              master_id={masterP.master_id}
              reload={loadMasterSingle}
            />
          </div>
          <div
            style={{
              flex: 1,
              height: "120px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "90%",
            }}
          >
            <Fund
              status={masterP.fund}
              master_id={masterP.master_id}
              reload={loadMasterSingle}
            />
            <LotVolume
              status={masterP.lot_volume}
              master_id={masterP.master_id}
              reload={loadMasterSingle}
            />
            <MidLimit
              status={masterP.mid_limit}
              master_id={masterP.master_id}
              reload={loadMasterSingle}
            />
            <FreshLimit
              status={masterP.fresh_limit}
              master_id={masterP.master_id}
              reload={loadMasterSingle}
            />
            <Pending
              status={masterP.delete_pending}
              master_id={masterP.master_id}
              reload={loadMasterSingle}
            />
          </div>
          <div
            style={{
              flex: 1,
              height: "120px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "90%",
            }}
          >
            <SqOff
              status={masterP.sq_off}
              master_id={masterP.master_id}
              reload={loadMasterSingle}
            />
            <Standing
              status={masterP.delete_standing}
              master_id={masterP.master_id}
              reload={loadMasterSingle}
            />
            <SymbolBrokerage
              status={masterP.symbol_brokerage}
              master_id={masterP.master_id}
              reload={loadMasterSingle}
            />
            <Trade
              status={masterP.delete_trade}
              master_id={masterP.master_id}
              reload={loadMasterSingle}
            />
          </div>
        </>
      ) : (
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          NO PERMISSION FOUND
        </div>
      )}
    </div>
  );
}
