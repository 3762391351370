import React, { useEffect, useState } from "react";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import { Dark } from "../../../../../Theme/Theme";
import Menu from "./Menu";
import McxCard from "./McxCard";
import Loading from "react-loading";
const backend = new Backend();

export default function Mcx(props) {
  let client = props.client;
  const [loading, setLoading] = useState(false);
  const [mcx, setMcx] = useState([]);

  useEffect(() => {
    load_mcx();
  }, []);

  const load_mcx = () => {
    setLoading(true);

    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      
      client_id: client.id,
    };
    backend.client_mcx(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setMcx(r.mcx);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Menu mcx={mcx} username={client.username} />
      <div className="scroll" style={{ height: "62vh", overflowY: "scroll" }}>
        {loading ? (
          <div
            style={{
              height: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading type="spin" color={Dark.text} height={35} width={35} />
          </div>
        ) : mcx.length > 0 ? (
          mcx.map((i, t) => {
            return (
              <McxCard item={i} index={t} client={client} reload={load_mcx} />
            );
          })
        ) : (
          <div
            style={{
              height: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
              {" "}
              Nothing here
            </div>
            <div style={{ color: Dark.text }}>No Ledger Report Found.</div>
          </div>
        )}
      </div>
    </div>
  );
}
