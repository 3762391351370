import React, { useEffect, useState } from "react";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import { Dark } from "../../../../../Theme/Theme";
import Menu from "./Menu";
import LedgerCard from "./LedgerCard";
import Loading from "react-loading";
const backend = new Backend();

export default function Ledger(props) {
  let master = props.master;
  const [loading, setLoading] = useState(false);
  const [ledger, setLedger] = useState([]);

  useEffect(() => {
    load_ledger();
  }, []);

  const load_ledger = () => {
    setLoading(true);

    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      
      master_id: master.id,
    };
    backend.master_ledger(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setLedger(r.ledger);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Menu ledger={ledger} username={master.username} />
      <div className="scroll" style={{ height: "62vh", overflowY: "scroll" }}>
        {loading ? (
          <div
            style={{
              height: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading type="spin" color={Dark.text} height={35} width={35} />
          </div>
        ) : ledger.length > 0 ? (
          ledger.map((i, t) => {
            return <LedgerCard item={i} index={t} ledger={ledger} />;
          })
        ) : (
          <div
            style={{
              height: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
              {" "}
              Nothing here
            </div>
            <div style={{ color: Dark.text }}>No Ledger Report Found.</div>
          </div>
        )}
      </div>
    </div>
  );
}
